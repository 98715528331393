// ============================================================================
// Invoices
// ============================================================================

.payment-methods {
  img {
    max-width: 100px;
    opacity: .7;
    &.active {
      opacity: 1;
    }
  }
}