// ============================================================================
// Overrides
// ============================================================================

// Foundation

.row {
  width: 100%;
  .row {
    width: auto;
  }
}

.bordered-rows {
  .row:not(.no-border) {

    &:not(:last-of-type) {
      border-bottom: 1px solid #dedede;
    }

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }

  //hyper specificity to fight foundation
  .accordion .accordion-item .accordion-title {
    font-size: 1rem;

    small {
      font-size: 75%;
      transform: translateY(12.5%);
      &.pull-right {
        margin-right: 25px;
      }
    }
  }
}

.column, .columns {
  //position: relative;
}

.reveal {
  width: 100%;
  max-width: 800px;
  @include breakpoint(medium) {
    width: 80%;
  }
  &.email-modal {
    max-width: 1200px;
  }
}

.tooltip {
  max-width: 600px !important;
}

.has-tip {
  border-bottom: 0;
}

// WAM

.phonetable {
  margin: 0 !important;
}

.cc_holder {
  display: inline-block;
  width: 100%;
  #phone_cc_dd {
    width: 100% !important;
  }
}

.errorField {
  @extend .is-invalid-input;
}

// Other

#buorg {
  background-color: white;
  padding: 5px;
  border-bottom: 3px solid red;
  box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.3);

  #buorgclose {
    top: 5px;
  }
}

.is-relative {
  position: relative;
}

.is-in-input {
  position: absolute;
  right: 25px;
  top: 10px;
}

.collapse-paragraph p {
  display: inline;
}

#__bs_notify__ {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 !important;
}

.kint {
  dl, dd, dt, ul, ol {
    width: 100% !important;
    max-width: 100% !important;
  }
}

input.ar {
  display: inline-block;
  float: left;
}

iframe[name="google_conversion_frame"] {
  display: none;
}