// ============================================================================
// Fonts
// ============================================================================

@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('../fonts/MyriadPro-Regular.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('../fonts/MyriadPro-Cond.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('../fonts/MyriadPro-SemiboldIt.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('../fonts/MyriadPro-Semibold.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('../fonts/MyriadPro-CondIt.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('../fonts/MyriadPro-BoldIt.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('../fonts/MyriadPro-BoldCondIt.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('../fonts/MyriadPro-BoldCond.otf') format('opentype');
}


@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('../fonts/MyriadPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Myriad Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('../fonts/MyriadPro-It.otf') format('opentype');
}