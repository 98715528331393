.accordion {
  .accordion-item {
    .accordion-title {
      font-size: rem-calc(25);
      padding: 1rem;
      color: $theme-alpha;
      background-color: transparent;
      &:hover, &:active, &:focus {
        color: $theme-alpha;
        background-color: transparent;
      }
      &:before {
        content: '';
        width: 20px;
        height: 12px;
        background-image: url("/public/images/chevron.png");
        background-repeat: no-repeat;
        background-size: 20px 12px;
        margin-top: -.35rem;
      }
    }
    .accordion-content {
      pre {
        white-space:pre-wrap;
      }
    }
  }
}