// ============================================================================
// Dropdown Styles
// ============================================================================

// Tick to title

.tick {
  &:after {
    content: '✔︎';
    font-size: 60px;
    margin-top: -25px;
    float: right;
    color: $theme-font-disabled;
  }
  &.active {
    &:after {
      color: $theme-alpha
    }
  }
}

// FYI this is because of prototype

.jq-dropdown-open {
  & + div.jq-dropdown {
    display: block !important;
  }
}

// Custom

.dropdown-select {
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 1px solid $theme-iota;
  margin-top: 40px;
  padding: 10px 50px 10px 10px;
  color: $theme-font-medium;
  .service {
    color: $theme-alpha;
  }
  &:after {
    content: '';

    display: inline-block;

    position: absolute;
    top: 12px;
    right: 10px;

    width: 30px;
    height: 20px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/public/images/chevron.png");
  }
}

// Plugin

.jq-dropdown {
  // Override default position because we use relative position parents
  top: auto !important;
  left: 15px !important;
  // Make all dropdowns full width (consistency and UI)
  right: 15px;
  // Make Pretty
  border: 1px solid $theme-iota;
  // Don't make too big
  max-height: 300px;
  overflow: auto;
  &:before, &:after {
    // Remove arrow indicator
    display: none !important;
  }
  .jq-dropdown-panel {
    // Force full width
    min-width: 0;
    width: 100%;
    max-width: 100%;
    // Make pretty
    padding: 20px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $theme-iota;
    box-shadow: none;
    &:hover, &:active, &:focus {
      background-color: $theme-gamma;
      * {
        color: $theme-font-light !important;
      }
    }
    p {
      margin-bottom: 0;
    }
    strong {
      color: $theme-font-dark;
    }
    h4 {
      margin-bottom: 0;
      color: $theme-font-medium;
      .price {
        font-weight: bold;
        color: $theme-font-dark;
      }
    }
  }
}