// ============================================================================
// Cards
// ============================================================================

.card {
  border-bottom: 15px solid $theme-alpha;
  margin-bottom: 20px;
  &.panel {
    border: 0;
    .head {

    }
    .body {

    }
    .foot {

    }
  }
}