/* The fancy colour names from the designo doc */
/* Primary */
$forest-green: #038541;
/* Secondary */
$mint-green: #64eebc;
/* Highlights */
$yellow: #fff161;
$watermelon: #ff9c92;

// ============================================================================
// Variables
// ============================================================================

$theme-alpha: $forest-green;
$theme-beta: $forest-green;

$theme-gamma: #1E85BF;
$theme-delta: #61b6d9;

$theme-epsilon: $mint-green;
$theme-zeta: #F0F0F0;
$theme-eta: #FFF;

$theme-theta: #D3D3D3;
$theme-iota: #000;
$theme-kappa: #777;
$theme-lambda: #555;
$theme-mu: #333;

$theme-nu: $mint-green;
$theme-ksi: #0055ee;

$theme-font-light: #FFF;
$theme-font-medium: #777;
$theme-font-dark: #222;
$theme-font-disabled: #ccc;

$body-font-family: "Work Sans", sans-serif;
$body-font-family-bold: "Work Sans Bold", sans-serif;
$body-font-family-italic: "Work Sans Italic", sans-serif;
$global-font-size: 0.9rem;
