// ============================================================================
// Main Stylesheet
// ============================================================================

// Imports
// ----------------------------------------------------------------------------

// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic|Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
@import url(https://fonts.googleapis.com/css?family=Work%20Sans:400,400i,700,700i);
@import "open-iconic/font/css/open-iconic-foundation.scss";
@import "fonts";

// Variables
@import "variables";

// Foundation
@import "foundation-sites/scss/foundation";
@import "rc-tooltip/assets/bootstrap";

// Site variables (has to be below framework import: https://github.com/leafo/scssphp/issues/387)
@import "global";
@include foundation-everything();

@include foundation-flex-classes;
@include foundation-flex-grid;

// Overrides
@import 'overrides';

// Scaffolding
@import "scaffolding";
@import "typography";

// Components
@import "navbar";
@import "lists";
@import "buttons";
@import "tables";
@import "infotables";
@import "cards";
@import "dropdowns";
@import "select";
@import "inputs";
@import "accordions";
@import "steps";
@import "stamp";
@import "pagination";
@import "footer";

// Pages
@import "generic";
@import "certificates";
@import "services";
@import "products";
@import "domains";
@import "invoices";
@import "banners";

// SHAME
@import "shame";
