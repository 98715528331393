// ============================================================================
// Buttons
// ============================================================================

.button {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1rem;
}

.btn-theme-primary {
  color: $theme-font-light;
  background-color: $theme-alpha;
  &:hover, &:active, &:focus {
    color: $theme-font-light;
    background-color: $theme-beta;
  }
}

.btn-theme-secondary {
  color: $theme-font-light;
  background-color: $theme-gamma;
  &:hover, &:active, &:focus {
    color: $theme-font-light;
    background-color: $theme-delta;
  }
}

.btn-pay-now {
  padding: 15px 50px;
  margin: 30px 0;
  &:disabled {
    opacity: .7;
    color: $theme-font-light;
    background-color: $theme-gamma;
  }
}

.btn-icon {
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  span, i {
    margin-right: 10px;
    font-size: 1.5rem;
  }
  span {
    vertical-align: text-top;
  }
  i {
    vertical-align: sub;
  }
}

.btn-large {
  padding: 15px 20px;
  border-radius: 8px;
  font-size: 1.6rem;
  font-weight: bold;
}

.btn-big {
  width: 100%;
  padding: 20px;
  font-size: rem-calc(24px);
}