[type="checkbox"] {
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
    &:disabled {
      + label {
        &:before {
          border-color: $theme-kappa;
          background-color: $theme-theta;
        }
      }
    }
    + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        left:0;
        width: 20px;
        height: 20px;
        border: 2px solid $theme-alpha;
      }
      &:after {
        content: '✔';
        position: absolute;
        top: 3px;
        left: 3px;
        font-size: 18px;
        line-height: 0.8;
        color: $theme-alpha;
        transition: all 200ms;
      }
    }
  }
  &:not(:checked) {
    + label {
      &:after {
        opacity: 0;
        transform: scale(0);
      }
    }
  }
  &:checked {
    + label {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}