// ============================================================================
// Generic
// ============================================================================

.texture {
  overflow: auto;
  &.restricted {
    overflow: initial;
  }
  &.primary {
    background-color: $theme-alpha;
  }
  &.light {
    background-color: $theme-eta;
  }
  &.medium {
    background-color: $theme-zeta;
  }
  &.dark {
    background-color: $theme-epsilon;
  }
}

.medium-right {
  @include breakpoint('medium') {
    float: right;
  }
}
// H O O K: This should be defined somewhere its required by our coding styles
.debugbox {
        background-color:#F2D5EB;
        border:1px solid #444444;
        color:#000000;
        font-size:90%;
        z-index:50000;
}

