// ============================================================================
// Responsive Tables
// ============================================================================

[data-column-title=''] {
  &:before {
    display: none !important;
  }
}

.data-table {

  // Shared

  margin: 20px 0;
  width: 100%;
  max-width: 100%;
  > div {
    background-color: $theme-eta;
    &.caption, > div {
      position: static;
      //overflow: auto; // Fix windows checkbox issue
      padding: 10px;
      .button {
        width: 100%;
        margin-bottom: 0;
      }
    }
    &.caption {
      margin-bottom: 20px;
    }

    > div {
      &[data-column-title="Due Date"]{
        //white-space: nowrap; //causes tables to push down sidebar
      }

      &[data-column-title="Amount"],
      &[data-column-header-title="Amount"]
      {
        white-space: nowrap;
        text-align: right;
      }

      .table-discount {

        text-decoration: line-through;
        color: #5A5A5A;

        &.active {
          text-decoration: none;
          color: $theme-alpha;
        }
      }
    }
  }

  @include breakpoint(large) {

    display: table;

    > div {
      border: 0;
      margin: 0;
      padding: 0;
      display: table-row;
      width: 100%;
      &:nth-of-type(odd) {
        background-color: $theme-eta;
      }
      &:nth-of-type(even) {
        background-color: $theme-zeta;
      }
      &.caption {
        display: table-caption;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        &.top {
          caption-side: top;
        }
        &.bottom {
          caption-side: bottom;
        }
      }
      > div {
        //word-break: break-all;
        min-width: 80px;
        display: table-cell;
        vertical-align: top;
        border-right: 0;
        &:last-of-type {
          border-bottom: 0;
        }
        &.extended {
          min-width: 140px;
        }
      }
    }
  }

  // Horizontal

  &.horizontal {

    > div {
      &.head {
        display: none;
        > div {
          //white-space: pre;
          color: $theme-font-light;
          background-color: $theme-mu;
        }
      }
      > div {
        &.caption {
          padding-top: 10px;
        }
        &:before {
          content: attr(data-column-title);
          display: block;
          padding: 10px;
          margin: -10px -10px 10px;
          font-weight: bold;
          color: $theme-font-light;
          background-color: #888;
        }
        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }

    @include breakpoint(large) {
      > div {
        &.head {
          display: table-row;
          width: 100%;

          > div {
            white-space:nowrap;

            i{
              margin-left: 4px;
            }
          }
        }
        &:before, > div:before {
          display: none !important;
        }
      }
    }
  }

  // Vertical

  &.vertical {
    > div {
      > div {
        &:first-of-type {
          font-weight: bold;
          color: $theme-font-light;
          background-color: $theme-mu;
        }
      }
    }

    @include breakpoint(large) {
      > div {
        > div {
          &:first-of-type {
            font-weight: normal;
            color: $theme-font-dark;
            background-color: transparent;
          }
        }
      }
    }
  }

  &.stats-table {
    > div {
      > div {
        &.graph {
          text-align: center;
        }
      }
    }
    @include breakpoint(large) {
      > div {
        > div {
          min-width: 0 !important;
          vertical-align: bottom;
          img {
            margin-bottom: -10px;
          }
        }
      }
    }
    &.graphs {
      overflow: auto;
      > div:not(.head) {
        > div {
          float: left;
          min-height: 160px;
          width: 50%;
          @include breakpoint(medium) {
            width: 33.33%;
          }
          @include breakpoint(large) {
            width: auto;
            float: none;
            min-height: 0;
          }
        }
      }
    }
    &.stats {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
    }
  }

}
