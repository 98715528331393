// ============================================================================
// Stamps
// ============================================================================

.stamp {
  border-radius: 5px;
  text-align: center;
  max-width: 300px;
  color: $theme-font-light;
  i {
    font-size: 30px;
    line-height: 1;
    color: $theme-alpha;
    background-color: $theme-eta;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 100%;
    margin-bottom: 10px;
  }
  span, strong {
    display: block;
  }
  span {
    font-size: 14px;
    font-style: italic;
  }
  strong {
    font-size: 18px;
  }
}