// ============================================================================
// Lists
// ============================================================================

.menu {
  &.wrapped {
    flex-wrap: wrap;
  }
}

.input-list {
  li {
    input[type="checkbox"] {
      vertical-align: middle;
    }
  }
}