// ============================================================================
// Typography
// ============================================================================

a {
  color: $theme-alpha;
  &:hover, &:active, &:focus {
    color: $theme-beta;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-size: rem-calc(45px);
  color: $theme-alpha;
  &.strong {
    font-family: $body-font-family-bold;
  }
}

h2 {
  &.strong {
    font-family: $body-font-family-bold;
  }
}

h3 {
  &.strong {
    font-family: $body-font-family-bold;
  }
}

h4 {
  color: $theme-alpha;
  font-weight: 400;
  margin-bottom: 0;
  &.strong {
    font-family: $body-font-family-bold;
  }
}

h5 {
  color: $theme-alpha;
  &.strong {
    font-family: $body-font-family-bold;
  }
}

h6 {
  &.strong {
    font-family: $body-font-family-bold;
  }
}

hr {
  &.thick {
    max-width: 100%;
    border-top: 10px solid $theme-alpha;
    border-bottom: 0;
    margin: 0;
  }
}

label {
  font-size: 1rem;
}

.text-error {
  color: $input-error-color;
}

.accordion-content pre {
  font-size: 75%;
}