// ============================================================================
// Service Page Styles
// ============================================================================

// NOTE: Dropdowns were so custom and large by themselves that they
// have been moved to an isolate SASS file "_dropdowns"

.services {
  &:not(.borderless) {
    .service-container {
      @include breakpoint(medium) {
        &:nth-child(odd) {
          border-right: 1px solid $theme-kappa;
        }
        &:nth-child(even) {
          margin-left: -1px;
          border-left: 1px solid $theme-kappa;
        }
      }
    }
  }
  .service-container {
    position: relative;
    color: $theme-font-medium;
    hr {
      border-bottom-color: $theme-kappa;
    }
    .service-details {
      h4 {
        .price {
          font-weight: bold;
          color: $theme-font-dark;
        }
      }
      a.button {
        width: 100%;
      }
    }
  }
}