// ============================================================================
// Footer
// ============================================================================

.footer {
  &.texture {
    &, a {
      color: #FFF
    }
  }
  .footer-menu {
    @include breakpoint(medium down) {
      display: block;
      li {
        display: inline-block;
        width: 100%;
        float: left;
      }
    }
  }
}