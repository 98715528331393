.domain-search-wrap {
  padding: 40px 0;
  //background-color: #e2e2e2;
}

.domain-search-bar {
  padding: 10px;
  background-color: $theme-alpha;
  button {
    line-height: 1 !important;
    padding: 15px 20px;
    margin-bottom: 0;
    border-radius: 8px;

    font-size: 1.6rem;
    font-weight: bold;
    color: $theme-alpha;
    background-color: #FFF;
    &:hover, &:active, &:focus {
      color: $theme-alpha;
      background-color: #FFF;
    }
  }
}

.domain-search-form {
  &.light {
    &:before {
      color: #FFF;
    }
  }
  &.extension {
    input {
      &:focus {
        + .postfix {
          border: #000000 1px solid;
          border-left: 0;
        }
      }
      @include breakpoint(medium up) {
        width: 40%;
      }
    }
     .postfix {
      transition: border ease 250ms;
      display: inline-block;
      margin-bottom: 15px;
      border: #cccccc 1px solid;
      border-left: 0;
      @include breakpoint(medium up) {
        width: 20%;
      }
      + button {
        @include breakpoint(medium up) {
          margin-right: calc(8% + 2px);
          margin-left: -2px;
          float: right;
        }
      }
    }
  }
  &:before, input, button {
    display: block;
    width: 100%;
    float: left;

    @include breakpoint(medium up) {
      display: inline-block;
    }
  }
  &:before {
    display: none;

    font-family: FontAwesome;
    content: '\f002';
    text-align: right;
    font-size: 40px;

    box-sizing: border-box;
    padding-right: 15px;

    @include breakpoint(medium up) {
      display: inline-block;
      width: 12%;
    }
  }
  input {
    font-size: 1.2rem;

    min-height: 65px;
    padding: 0 25px;

    @include breakpoint(medium up) {
      width: 60%;
    }
  }
  button {
    min-height: 65px;
    margin-bottom: 0;
    font-size: 18px;

    @include breakpoint(medium up) {
      width: 20%;
    }
  }
}

.domain-search-card {
  background-color: #FFF;
  min-height: 150px;
  padding: 20px 40px;
  border-bottom: 6px solid $theme-alpha;
}

.domain-search-item {
  margin-bottom: 10px !important;
  min-height: 45px;
  border-bottom: 1px solid #CCC;
}

.domain-search-price {
  select {
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #CCC;
    width: calc(100% - 10px);
    display: inline-block;
    &:focus {
      outline: none;
    }
  }
}

.domain-search-top-list {
  padding: 40px 0;
  .available-domains, .un-available-domains, .moderation-available-domains {
    margin-bottom: 20px;
    font-weight: 400 !important;
    &:before {
      font-family: FontAwesome;
      display: inline-block;

      margin-right: 10px;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      line-height: 60px;
      text-align: center;
      font-size: 40px;
    }
  }
  .available-domains {
    color: $theme-gamma;
    &:before {
      content: '\f00c';

      color: #FFF;
      background-color: $theme-gamma;
    }
  }
  .un-available-domains {
    // https://color.adobe.com/create/color-wheel/?base=2&rule=Complementary&selected=4&name=My%20Color%20Theme&mode=rgb&rgbvalues=0.0027789046653143774,0.26862745098039215,0.13383100496141306,0.10483434753211622,0.8686274509803922,0.48135207458389245,0.011764705882352941,0.5686274509803921,0.28627450980392155,0.26862745098039215,0.02905926846485593,0,0.5686274509803921,0.07200435729855059,0.011764705882352901&swatchOrder=0,1,2,3,4
    color: #911203;
    &:before {
      content: '\f00d';

      color: #FFF;
      background-color: #911203;
    }
  }
  .moderation-available-domains {
    // https://color.adobe.com/create/color-wheel/?base=2&rule=Complementary&selected=4&name=My%20Color%20Theme&mode=rgb&rgbvalues=0.0027789046653143774,0.26862745098039215,0.13383100496141306,0.10483434753211622,0.8686274509803922,0.48135207458389245,0.011764705882352941,0.5686274509803921,0.28627450980392155,0.26862745098039215,0.02905926846485593,0,0.5686274509803921,0.07200435729855059,0.011764705882352901&swatchOrder=0,1,2,3,4
    color: #b5740d;
    &:before {
      content: '\f069';

      color: #FFF;
      background-color: #b5740d;
    }
  }
  .domain-search-domain, .domain-search-button {
    font-size: 1.4em;
  }
  .domain-search-price select {
    font-size: 1.2em;
  }
}

.domain-search-list {
  list-style: none;
  margin-left: 0;
  li {
    font-size: 1.2rem;
    padding-left: 40px;
    min-height: 25px;
    margin-bottom: 15px;
    &.active {
      font-weight: bold;
      background-image: url("/themes/default/public/images/tick.png");
      background-size: 30px 23px; // 4:3
      background-repeat: no-repeat;
      background-position: left;
    }
  }
}

.domain-search-domain {
  font-size: 1.2rem;
  display: inline-block;
  margin: 5px 0;
}

.domain-search-non-interactive {
  display: inline-block;
  float: right;

  margin-top: 5px !important;

  // https://color.adobe.com/create/color-wheel/?base=2&rule=Complementary&selected=4&name=My%20Color%20Theme&mode=rgb&rgbvalues=0.0027789046653143774,0.26862745098039215,0.13383100496141306,0.10483434753211622,0.8686274509803922,0.48135207458389245,0.011764705882352941,0.5686274509803921,0.28627450980392155,0.26862745098039215,0.02905926846485593,0,0.5686274509803921,0.07200435729855059,0.011764705882352901&swatchOrder=0,1,2,3,4
  color: #911203;
  &:hover, &:active, &:focus {
    color: #911203;
  }
}

.domain-search-button {
  transition: none;
  float: right;
  padding: 7px 15px;
  border-radius: 6px;
  color: #FFF;
  background-color: $theme-alpha;
  line-height: normal;

  margin: 15px 0;
  @include breakpoint(medium up) {
    margin: 0;
  }
  &:hover, &:active, &:focus {
    outline: none;
    background-color: $theme-alpha;
    color: #FFF;
  }
  &:before, &:after {
    font-family: FontAwesome;
    display: inline-block;
  }
  &:before {
    margin-right: 5px;
  }
  &:after {
    margin-left: 5px;
  }
  &.check-available {
    &:before {
      content: '\f002';
    }
  }
  &.not-in-cart {
    &:before {
      content: '\f067';
    }
  }
  &.update-cart {
    &:before {
      content: '\f067';
    }
  }
  &.remove-from-cart {
    padding: 0;
    font-size: 12px;
    text-align: right;
    color: #333;
    background-color: transparent !important;
    &:before {
      font-family: "Lato", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
      content: 'Selected';
      display: block;
      min-height: 25px;

      padding-right: 35px;
      background-image: url("/themes/default/public/images/tick.png");
      background-size: 30px 23px; // 4:3
      background-repeat: no-repeat;
      background-position: right;

      font-size: 1.1rem;
      color: $theme-alpha;

    }
    &:after {
      content: '\f056';
      margin-right: 20px;
    }
  }
}

.legend-header {
  padding: 2px 15px;
  margin-top: 20px;
  color: #FFF;
  background-color: $theme-epsilon;
}

.legend {
  .legend-register, .legend-pre, .legend-follow {
    margin-top: 10px;
    min-height: 30px;
    margin-right: -10px;
  }

  .legend-register {
    background-color: $theme-gamma;
  }
  .legend-pre {
    background-color: $theme-alpha;
  }
  .legend-follow {
    background-color: $theme-epsilon;
  }
}
