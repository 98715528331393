// ============================================================================
// Scaffolding
// ============================================================================

html, body {
  //overflow: auto;
}

.wrapper {
  //height: 100%;
}

.select {
  position: relative;
  overflow: hidden;
  display: block;
  min-width: 120px;
  margin-bottom: 20px;
  select {
    margin-bottom: 0;
    background-color: $theme-eta;
    &[disabled] {
      background-color: $theme-theta !important;
    }
  }
  &:after {
    content: '';

    display: inline-block;

    pointer-events: none;

    position: absolute;
    top: 9px;
    right: 5px;

    width: 30px;
    height: 20px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $theme-eta;
    background-image: url("/public/images/chevron.png");
  }
}

dl {
  display: block;
  overflow: auto;
  dt, dd {
    display: inline-block;
    width: calc(50% - 20px); // Padding
    padding: 5px 10px;
  }
}

.has-hide {
  display: inline !important;
}

.strong {
  font-weight: bold;
}

.wrap-text {
  word-break: break-all;
  word-wrap: break-word;
}

.strike-through {
  text-decoration: line-through;
}

