.affiliate-banners {
  .banner {
    //overflow: auto;
    position: relative;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid $theme-mu;
    input {
      vertical-align: top;
      display: inline-block;
      height: auto;
      width: auto;
      margin: 0 !important;
      font-size: 12px; // This is because they use the size attribute to define the width
      &[style*="margin-top:38px"] {
        margin-left: 11px !important;
        margin-top: 20px !important;
        + input {
          margin-top: 20px !important;
        }
      }
      &[style*="margin: 0 0 0 135px"] {
        margin-left: 135px !important;
        margin-top: 20px !important;
      }
    }
    span.select {
      display: inline-block;
      min-width: 0;
      width: 102px;
      margin-top: 20px;
      &:after {
        margin-top: -9px;
      }
      select {
        height: auto;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
      }
      + input {
        + input {
          + input {
            margin-top: 20px !important;
          }
        }
      }
    }
  }
}