// ============================================================================
// Products / Cart Page
// ============================================================================

.product-headings {
  z-index: 1;
  position: relative;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.25);
}

.products {
/*  margin-top: 20px; */
  .products-section {
    border-bottom: 1px solid $theme-kappa;
    &:nth-of-type(odd) {
      background-color: $theme-zeta;
    }
    &:nth-of-type(even) {

    }
    .product {
      .remove {
        font-size: 30px;
      }
    }
  }
}

.checkout {
  .input-group {
    .input-group-field {
      border-radius: 3px;
      border: 2px solid $theme-kappa;
      background-color: $theme-zeta;
      margin-right: 10px;
    }
    .input-group-button {
      .button {
        border-radius: 6px;
        background-color: $theme-lambda;
      }
    }
  }
}
