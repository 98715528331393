// ============================================================================
// Select2 Styling
// ============================================================================

$height: 38px;

.select2 {
  &.select2-container {
    .selection {
      > span {
        height: $height;
        border-radius: 0;
        box-shadow: none !important; // Removes bizarre focus effect.
        > span {
          line-height: $height;
          &[class*='arrow'] {
            height: 36px;
          }
        }
      }
    }
  }
}