.pagination {
  .current {
    background-color: $theme-alpha;
  }
  .pagination-previous {
    a {
      &:before {
        display: none;
      }
    }
  }
  .pagination-next {
    a {
      &:after {
        display: none;
      }
    }
  }
}