// ============================================================================
// Navigation
// ============================================================================

.top-bar {
  flex-wrap: wrap;
  @include breakpoint(large) {
    flex-wrap: nowrap;
    width: 100%;
  }
  .top-bar-left {
    flex: 1 1 auto;
    width: 100%;
    @include breakpoint(large) {
      flex: 1 1 0;
      max-width: 145px;
    }
    img {
      max-width: 260px;
    }
  }
  .top-bar-right {
    ul {
      li {
        &:first-of-type {
          @include breakpoint(large) {
            margin-left: auto;
          }
        }
      }
    }
  }
}

.primary-nav {
  padding-top: rem-calc(20px);
  padding-bottom: rem-calc(20px);
  background-color: transparent;
  ul.menu {
//    min-height: 90px;
    background-color: transparent;
    li {
      a {
        //font-size: rem-calc(14px);

        font-weight: 800;
        text-transform: uppercase;

        color: $theme-font-dark;
        &:hover, &:active, &:focus {
          color: $theme-alpha;
        }

        .img-logo {
          width: auto;
          max-height: 70px;
        }
      }
    }
  }
}

.secondary-nav {
  ul {
    li {
      a {
        color: $theme-font-light;

        .badge {
          min-width: 0;
          width: rem-calc(20px);
          height: rem-calc(20px);
          padding: .5em;
          position: relative;
          left: -5px;
          bottom: -10px;
          margin-bottom: -10px; // Clears overflow
          &.alert {
            background-color: red;
          }
        }
      }
    }
  }
}
